
export function DisplayConfirmationDialog(message, confirm_button_message, cancel_button_message, callback) {

    let is_confirmed = false;

    // Create main dialog wrapper - full screen div
    const confirmation_dialog = document.createElement("div");
    confirmation_dialog.classList.add("confirmation_dialog");

    // Create content container div
    const confirmation_dialog_content = document.createElement("div");
    confirmation_dialog_content.classList.add("confirmation_dialog_content");

    // Create paragraph of the dialog message
    const confirmation_dialog_message = document.createElement("p");
    confirmation_dialog_message.innerHTML = message;
    confirmation_dialog_message.classList.add("confirmation_dialog_message");

    // Create div container for the buttons
    const confirmation_dialog_buttons_div = document.createElement("div");
    confirmation_dialog_buttons_div.classList.add("confirmation_dialog_buttons_div");

    // Define buttons outside the if blocks so they are scoped correctly
    let confirmation_dialog_button = null;
    let confirmation_dialog_cancel_button = null;

    // If the confirm button message doesn't equal ""
    if (confirm_button_message !== "") {

        // Create a confirm button with cancel message
        confirmation_dialog_button = document.createElement("button");
        confirmation_dialog_button.innerHTML = confirm_button_message;
        confirmation_dialog_button.classList.add("confirmation_dialog_button");

    }

    // If the cancel button message doesn't equal ""
    if (cancel_button_message !== "") {

        // Create a cancel button with cancel message
        confirmation_dialog_cancel_button = document.createElement("button");
        confirmation_dialog_cancel_button.innerHTML = cancel_button_message;
        confirmation_dialog_cancel_button.classList.add("confirmation_dialog_button");

    }

    // Build DOM structure by appending everything together
    confirmation_dialog_content.appendChild(confirmation_dialog_message);
    confirmation_dialog_content.appendChild(confirmation_dialog_buttons_div);

    // Check if there us a confirm button
    if (confirmation_dialog_button) {

        // There is so append it
        confirmation_dialog_buttons_div.appendChild(confirmation_dialog_button);

    }

    // Check if there is a cancel button
    if (confirmation_dialog_cancel_button) {

        // There is so append it
        confirmation_dialog_buttons_div.appendChild(confirmation_dialog_cancel_button);

    }

    confirmation_dialog.appendChild(confirmation_dialog_content);
    document.body.appendChild(confirmation_dialog);



    // Event handlers
    // Check if a confirm button exists 
    if (confirmation_dialog_button) {

        // It does so add a click event to it
        confirmation_dialog_button.addEventListener("click", () => {

            // Set is_confirmed true
            is_confirmed = true;

            // Close dialog box by removing the div
            document.body.removeChild(confirmation_dialog);

            // Return true value when confirm button clicked
            // Check if the callback is a function (we want to do something with the response)
            if (typeof callback === "function") {
                callback(is_confirmed); // or false, depending on the action
            }

        });
    }

    // Check if a cancel button exists
    if (confirmation_dialog_cancel_button) {

        // It does so add a click event to it
        confirmation_dialog_cancel_button.addEventListener("click", () => {

            // Set is_confirmed false
            is_confirmed = false;

            // Close dialog box by removing the div
            document.body.removeChild(confirmation_dialog);

            // Return false value when cancel button clicked
            // Check if the callback is a function (we want to do something with the response)
            if (typeof callback === "function") {
                callback(is_confirmed); // or false, depending on the action
            }


        });
    }

}

