// Import react elements
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// Import layout components
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

// Import hooks
import useIsMobile from "global/hooks/useIsMobile";



const Layout = () => {

    // Set isMobile from use hook 
    const isMobile = useIsMobile();

    // Set a state for the side bar open/close toggle
    const [sidebarOpen, setSidebarOpen] = useState(true);

    // Hide sidebar by default on mobile
    useEffect(() => {

        // Check if mobile size screen
        if (isMobile) {

            // It is mobile

            // Hide sidebar by default
            setSidebarOpen(false);

        }

    // Run whenever isMobile changes
    }, [isMobile]);



    // Set location to useLocation() which gives access to the current URL/location object
    const location = useLocation();


    // Set a state for the page title
    const [pageTitle, setPageTitle] = useState("");



    // Run on location.pathname change - to update page title - sent to Header
    useEffect(() => {

        // Set current title by reading title from `document.title` - and replace the 'Strichsuite - ' bit with nothing
        const currentTitle = document.title.replace("Strichsuite - ", "");

        // Set the pageTitle
        setPageTitle(currentTitle);

    }, [location.pathname]);



    return (

        <div className="layout">

            <Sidebar toggleSidebar={() => setSidebarOpen(!sidebarOpen)} sidebarOpen={sidebarOpen} isMobile={isMobile} />

            { /* <div className={`content ${sidebarOpen ? "sidebar_open" : "sidebar_closed"}`}> */ }
            <div className={`content ${isMobile ? "is_mobile" : "is_desktop"} ${!sidebarOpen ? "sidebar_closed" : "sidebar_open"}`}>

                <Header toggleSidebar={() => setSidebarOpen(!sidebarOpen)} pageTitle={pageTitle} sidebarOpen={sidebarOpen} isMobile={isMobile} /> 

                <main className="main">

                    {/* This renders the page content */}
                    <Outlet /> 

                </main>

                <Footer />

            </div>

        </div>
    );
    


};

export default Layout;
