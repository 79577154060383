// Import react elements
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Import core functions
import { resetPassword } from "api/APICoreCalls";

// Import global hooks
import useFormHandler from "global/hooks/useFormHandler";

// Import components
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { DisplayConfirmationDialog } from "utilities/DialogBox";
import { GetCookie } from "utilities/Cookies"


const ResetPasswordForm = ({ onSwitch }) => {



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Set prefill_email to prefill_email cookie contents
    const prefill_email = GetCookie("prefill_email");



    // Extract form-related state and functions from the `useFormHandler` custom hook
    const {

        formValues,     // Stores the current values of form inputs
        handleChange,   // Function to update form values when inputs change
        handleSubmit,   // Function to handle form submission logic
        fieldErrors,    // Object storing validation errors for individual fields
        formError,      // General error message (e.g. API errors)
        loading,        // Boolean indicating whether the form is submitting
        apiResponse,    // Stores the response from the API after submission

    } = useFormHandler({

        // Default values for the form fields
        initialValues: {

            email: prefill_email || "",

        },

        // API function to be called on form submission
        apiCall: resetPassword,


        // Validation function to check the form fields before submission
        validate: (values) => {

            // Set errors to empty
            const errors = {};

            // Validation rules
            if (!values.email.includes("@")) errors.email = "Invalid email";
            
            // Return validation errors (if any)
            return errors;
        },
    });



    // useEffect runs whenever `apiResponse` changes - give user 'check your email' pop up
    useEffect(() => {

        // Check if there is a response
        if (apiResponse) {

            // Check if the response is successful
            if (apiResponse.success) {

                // It is so display a confrimation dialog
                DisplayConfirmationDialog(

                    // Dialog message
                    "Check your email to reset your password.",

                    // Confirm button title
                    "OK",

                    // Cancel button title - empty because we don't want a cancel button
                    "",

                    // Returned response from the dialog box
                    function (is_confirmed) {

                        // Check if the response is TRUE
                        if (is_confirmed) {

                            // It is so navigate to the login page
                            navigate("/login");

                        }

                    }

                );

            }

        }

    }, [apiResponse, navigate]);



    return (

        <div className="form-container">
            <h2>Reset Password</h2>
            <br />

                <form onSubmit={handleSubmit}>

                <input name="email"
                    type="email"
                    placeholder="Enter Your Email"
                    value={formValues.email}
                    onChange={handleChange}
                />
            
                <br />
                {fieldErrors.email && <p style={{ color: "red" }}>{fieldErrors.email}</p>}
                {formError && <p style={{ color: "red" }}>{formError}</p>}
                <br />
                <br />

                <CreateButton title={loading ? "Sending Reset Link..." : "Send Reset Link"} size="l" fullWidth="true" type="submit" disabled={loading} />

            </form>
                      
            <br />
            <br />
            
            <CreateButton title="Back to Login" size="s" onClick={() => onSwitch("login")} />
            

        </div>
    );
};

export default ResetPasswordForm;