// src/AppRouter.js

//Had to add this to handle empty url for activating new accounts
//don't need it (could just do it in app.js if the activiation url started with 'activate' then we could check for it in route in app eaisly)

// Import react elements
import { useLocation } from "react-router-dom";

// Import components
import ProtectedRoute from "global/components/ProtectedRoute";
import Layout from "global/components/layout/Layout";

// Immport hooks
import ActivateAccount from "pages/login/hooks/useActivateNewAccount";



const AppRouter = () => {



    // Set location to useLocation() which gives access to the current URL/location object
    const location = useLocation();



    // Check if we're trying to activate a new account
    const isActivation = location.search.includes("action=activate_account");


    // Check if isActivation is true
    if (isActivation) {

        // It is

        // Run 'ActivateAccount'
        return <ActivateAccount />;

    }



    return (

        <ProtectedRoute>

            <Layout />

        </ProtectedRoute>

    );



};

export default AppRouter;