// Import react elements
import { useContext, useState, useEffect } from "react";

// Import strich api calls
import { getOrder } from "api/APIStrichCalls";

// Import context function
import { AuthContext } from "global/context/AuthContext";

// Import components
import { GridItem } from "global/components/Grid";



const OrdersList = () => {

    // Get the token from AuthContext
    const { token, authReady } = useContext(AuthContext);

    // Set a state for the user data retrieved from the API
    const [orderData, setOrderData] = useState("");



    // Run once - get user data from the API when the token is available or changes
    useEffect(() => {

        // Check if there's no token or if the authReady flag is false
        if (!authReady || !token)

            // Exit early
            return;

        // We have a token and the AuthReady flag is true
        // Make the API call to log in and get the response
        getOrder(token, setOrderData);

        //console.log(orderData);

        // Re-run if the token or flag changes
    }, [authReady, orderData, token]); 



    return (

        <GridItem>

            <h3>Orders</h3>

            <br />

            <p>

                {orderData}

            </p>

            <table>

           

            </table>







        </GridItem>

    );

};

export default OrdersList;