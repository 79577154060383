// Import react elements
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// Import context
import { AuthProvider } from "global/context/AuthContext";
import { UserProvider } from "global/context/UserContext";
import { ThemeProvider } from "global/context/ThemeContext";

// Import AppRouter (don't need this if we change the link for activating an accont)
import AppRouter from "AppRouter";
// Need this is we change the above
import ProtectedRoute from "global/components/ProtectedRoute";
import Layout from "global/components/layout/Layout"; // Sidebar & Header included
import ActivateAccount from "pages/login/hooks/useActivateNewAccount";

// Import pages
import Login from "pages/login/LoginPage";
import Dashboard from "pages/dashboard/DashboardPage";
import Orders from "pages/orders/OrdersPage";
import Images from "pages/images/ImagesPage";
import Account from "pages/account/AccountPage";
import Connections from "pages/account/sub_pages/connections/ConnectionsSubPage";



const App = () => {
    return (
        <AuthProvider>
            <UserProvider>
                <ThemeProvider>
                    <Router>
                        <Routes>

                            {/* Public Route: Login Page */}
                            {/* All login-related pages share the same Login component */}
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Login />} />
                            <Route path="/reset-password" element={<Login />} />
                            <Route path="/update-password" element={<Login />} />



                            {/* Catch-All for Root Path */}
                            {/* Only authorised users can access these */}
                            <Route path="/" element={<AppRouter />}>
                                <Route index element={<Navigate to="/dashboard" />} />
                                <Route path="dashboard" element={<Dashboard />} />
                                <Route path="orders" element={<Orders />} />
                                <Route path="images" element={<Images />} />
                                <Route path="account" element={<Account />} />
                                <Route path="account/connections" element={<Connections />} />
                            </Route>


                        </Routes>
                    </Router>
                </ThemeProvider>
            </UserProvider>
        </AuthProvider>
    );
};

export default App;
















