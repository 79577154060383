// Import react elements
import { useEffect, useState } from "react";



const useIsMobile = (breakpoint = 768) => {



    // Set state for isMobile based on screen size less than 768
    const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);



    useEffect(() => {

        // Function to handle screen resize
        const handleResize = () => {

            // Set the state true/false based on screen size
            setIsMobile(window.innerWidth <= breakpoint);

        };

        // Add event listener for screen resize
        window.addEventListener("resize", handleResize);

        // Remove the event
        return () => window.removeEventListener("resize", handleResize);

    // Run on breakpoint change / update
    }, [breakpoint]);

    // Return state (true/false)
    return isMobile;

};

export default useIsMobile;