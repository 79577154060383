// Import react elements
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import context
import { AuthContext } from "global/context/AuthContext";
import { ThemeContext } from "global/context/ThemeContext";

// Import components
import CreateButton from "global/components/CreateButton";

// Import utility functions
import { DisplayConfirmationDialog } from "utilities/DialogBox";



const Header = ({ toggleSidebar, pageTitle, sidebarOpen, isMobile }) => {

    // Get the logout function from AuthContext
    const { logout } = useContext(AuthContext);

    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Handle logout button click
    const handleLogout = () => {

        // Give dialog - are you sure you want to log out?
        DisplayConfirmationDialog(

            // Dialog message
            "Are you sure you want to log out?",

            // Confirm button title
            "OK",

            // Cancel button title
            "Cancel",

            // Returned response from the dialog box
            function (is_confirmed) {

                // Check if the response is TRUE
                if (is_confirmed) {

                    // It is

                    // Run logout function
                    logout();

                    // Navigate to the login page
                    navigate("/login");

                }
            }
        );

    };



    // Get the theme switching function from ThemeContext
    const { theme, setTheme, primary, setPrimary } = useContext(ThemeContext);

    // Set a astate for showing the settings icon dropdown (closed by default)
    const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);

    // Create reference for detecting clicks outside the settings dropsdown
    const dropdownRef = useRef(null); 

    // Close dropdown when clicking outside
    useEffect(() => {

        const handleClickOutside = (event) => {

            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

                setShowSettingsDropdown(false);

            }

        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {

            document.removeEventListener("mousedown", handleClickOutside);

        };

    }, []);


    // Create an svg icon for 'settings cog' --Not sure about this might use black/white pngs instead elle 26/03/25
    const SettingsIcon = () => (
        <svg width="29" height="24" viewBox="0 0 32 32" fill="var(--primary-color)" xmlns="http://www.w3.org/2000/svg">
            <path d="M28,5.86c-0.13.33-.24,0.63-0.36,0.93a6.13,6.13,0,0,0-.62,2.91,4.84,4.84,0,0,0,2.68,4.08c0.56,0.29,1.14.53,1.72,0.78a0.38,0.38,0,0,1,.28.41c0,0.69,0,1.39,0,2.08a0.35,0.35,0,0,1-.25.39c-0.49.2-1,.43-1.46,0.65a4.94,4.94,0,0,0-2.68,6.28c0.18,0.52.39,1,.6,1.56a0.38,0.38,0,0,1-.1.48c-0.49.47-1,.95-1.45,1.44a0.41,0.41,0,0,1-.51.11,16.77,16.77,0,0,0-2.38-.8,4.89,4.89,0,0,0-5.45,2.9c-0.22.48-.42,1-0.66,1.45a0.48,0.48,0,0,1-.35.23c-0.7,0-1.41,0-2.12,0a0.39,0.39,0,0,1-.41-0.28,18,18,0,0,0-1.34-2.55,4.8,4.8,0,0,0-5.32-1.63c-0.61.2-1.21,0.44-1.8,0.68a0.38,0.38,0,0,1-.49-0.1c-0.48-.5-1-1-1.47-1.47A0.39,0.39,0,0,1,4,25.91a17,17,0,0,0,.8-2.34A4.89,4.89,0,0,0,2,18.09c-0.48-.22-1-0.44-1.46-0.65A0.37,0.37,0,0,1,.25,17q0-1,0-2.08a0.38,0.38,0,0,1,.28-0.41c0.59-.26,1.18-0.51,1.75-0.8a4.92,4.92,0,0,0,2.42-5.9C4.51,7.26,4.27,6.68,4,6.09a0.38,0.38,0,0,1,.1-0.49Q4.88,4.88,5.6,4.13A0.39,0.39,0,0,1,6.09,4a19.51,19.51,0,0,0,2.53.83A4.85,4.85,0,0,0,13.76,2.3C14,1.71,14.31,1.11,14.56.5A0.35,0.35,0,0,1,14.95.25c0.69,0,1.39,0,2.08,0a0.41,0.41,0,0,1,.43.3A17.93,17.93,0,0,0,18.73,3a4.84,4.84,0,0,0,5.4,1.71C24.74,4.51,25.35,4.25,26,4a0.44,0.44,0,0,1,.38.06c0.54,0.51,1.06,1,1.58,1.57A1.08,1.08,0,0,1,28,5.86ZM7,16A9,9,0,1,0,16,7,9,9,0,0,0,7,16Z" />
        </svg>
    );


    return (

        <header className="header">

            <div className="header-container-left">

                {/* Sidebar toggle open/closed button */}

                {isMobile  ?

                    sidebarOpen ?

                        ""
                        : 

                        <button className="header-container-left__toggle-sidebar-btn" onClick={toggleSidebar}>{sidebarOpen ? "-" : <img src="/images/icon_menu.png" alt="menu" className="header_menu_icon" />}</button>

                    :

                    <button className="header-container-left__toggle-sidebar-btn" onClick={toggleSidebar}>{sidebarOpen ? "<" : ">"}</button>

                }
                


                {/* Current page title */}
                <div className="header-container-left__pagetitle">{pageTitle}</div>

            </div>

            <div className="header-container-right">

                {/* Settings icon & dropdown */}
                <div className="settings-container" ref={dropdownRef}>

                    <button className="settings-icon" onClick={() => setShowSettingsDropdown(!showSettingsDropdown)}>
                        {SettingsIcon()}
                    </button>

                    {showSettingsDropdown && (

                        <div className="dropdown settings-dropdown fade-in">
                            <label>Theme:</label>
                            <select value={theme} onChange={(event) => setTheme(event.target.value)}>
                                <option value="light">Light</option>
                                <option value="dark">Dark</option>
                            </select>

                            <label>Primary Color:</label>
                            <div className="primary-preview">
                                <button className="preview purple" onClick={() => setPrimary("purple")}></button>
                                <button className="preview blue" onClick={() => setPrimary("blue")}></button>
                                <button className="preview pink" onClick={() => setPrimary("pink")}></button>
                                <button className="preview orange" onClick={() => setPrimary("orange")}></button>
                                <button className="preview green" onClick={() => setPrimary("green")}></button>
                            </div>

                        </div>
                    )}

                </div>

                {/* Log out button */}
                <CreateButton title="Log Out" size="s" onClick={handleLogout} />


            </div>


        </header>
    );
};

export default Header;