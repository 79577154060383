// Import react elements
import React from 'react';
import ReactDOM from 'react-dom/client';

// Import App
import App from './App';

// Import css stylesheet
import "global/css/Global.css";



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <App />

);