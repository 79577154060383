
// Import hooks
import usePageTitle from "global/hooks/usePageTitle";

// Import components
import { Grid } from "global/components/Grid";
import OrdersList from "./components/OrdersList";

// OrdersPage.js
const Orders = () => {

    // Set title inside the page
    usePageTitle("Orders"); 

    return (

        <Grid>

            {OrdersList()}

        </Grid>

    );
};

export default Orders;