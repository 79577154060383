
const ConnectionsTable = ({ connectionsData }) => {

    //console.log("Connections Table data:", connectionsData)

    // Check if there is any connections data or that the array of connections is more than 0
    if (!connectionsData || connectionsData.length === 0) {

        // Display to user
        return <p>No connections found.</p>;

    }



    return (

        <table className="connections-table">

            <thead>

                <tr>
                    <th>User Order</th>
                    <th>ID</th>
                    <th>Connection Name</th>
                </tr>

            </thead>

            <tbody>

                {connectionsData.map((conn) => (
                    <tr key={conn.userorder}>
                        <td>{conn.userorder}</td>
                        <td>{conn.id}</td>
                        <td>{conn.connectionname}</td>
                        
                    </tr>
                ))}

            </tbody>

        </table>
    );

};

export default ConnectionsTable;