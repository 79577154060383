
const Footer = () => {

    return (

        <footer>

            <div className="footer">

                <div className="footer-container-right">

                    <p>&#169; StrichSuite {new Date().getFullYear()} - A Pantheon Business Group Service</p>

                </div>

            </div>

        </footer>

    );

};

export default Footer;