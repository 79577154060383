// Import API call
import { strichCall } from './APICoreCalls';



export function getUserAccountData(token, set_account_details) {

    strichCall("GET", "accounts/account", token)
        .then((response) => {
            //console.log(response)
            set_account_details(response["results"]);
        })

    return;
}

export function getConnections(token, set_connections_details) {

    strichCall("GET", "connections/connections", token)
        .then((response) => {
            //console.log(response)
            set_connections_details(response["results"]);
        })

    return;
}




export function requestEmailChange(token) {

    strichCall("GET", "request_email_change", token)
        .then((response) => {
            //console.log(response)
        })

    return;
}



// Needs order id and connection id for the call
export function getOrder(token, setOrderData) {

    strichCall("GET", "orders/order", token)
        .then((response) => {
            //console.log(response)
            setOrderData(response["results"]);
        })

    return;
}


