// Import react elements
import { useState } from "react";
import { useNavigate } from "react-router-dom";



/**
 * Handles form state and submission logic
 * 
 * `config` is an object
 * @param {Object} config 
 * Object with default field values
 * @param {Object} config.initialValues
 * Async function to call on submit (api call)
 * @param {Function} config.apiCall
 * Optional validator returning field-level errors
 * @param {Function} [config.validate]
 * Optional redirect path
 * @param {string} [config.redirectOnSuccess]
 */



const useFormHandler = ({ initialValues, apiCall, validate, redirectOnSuccess }) => {



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Set initial states
    const [formValues, setFormValues] = useState(initialValues);
    const [fieldErrors, setFieldErrors] = useState({});
    const [formError, setFormError] = useState("");
    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);



    // Handles changes in form input fields and updates state accordingly when user types in them
    const handleChange = (event) => {

        // Destructure the `name` and `value` from the event's target (the input field)
        const { name, value } = event.target;

        // Update the form values state - Preserve previous values (`...prev`) - Update only the field that changed (`[name]: value`)
        setFormValues((prev) => ({ ...prev, [name]: value }));      

        // Clear any existing validation error as the user types - Preserve previous field errors (`...prev`) - Set the current field�s error to an empty string
        setFieldErrors((prev) => ({ ...prev, [name]: "" }));

    };



    // Handle form submission
    const handleSubmit = async (event) => {

        // Prevents page reload
        event.preventDefault();

        // Clear previous form errors
        setFormError("");

        // Reset all field-specific errors
        setFieldErrors({});

        // Clear previous API response
        setApiResponse(null);

        // Check if a validation function is provided, use it to check for errors
        if (validate) {

            // It is

            // Set errors based on validating the form input values
            const errors = validate(formValues);

            // Check if there are validation errors
            if (Object.keys(errors).length > 0) {

                // There are

                // Update error state
                setFieldErrors(errors);

                // Stop submission because we have an error
                return;

            }

        }

        // Set 'Loading' state to true while we run the api call
        setLoading(true);

        // Set the response to Run the api call with the form values
        const response = await apiCall(formValues);
        
        // Store the api response in a state so we can return it
        setApiResponse(response); 
               
        // Set 'Loading' state to false now api call has finished
        setLoading(false);

        // Check if the api call was unsuccessful
        if (!response?.success) {

            // It was unsuccessful

            // Set the formError state with the api response error
            setFormError(response?.error || response.error_message);
        }

        // Optional redirect
        // Check if the api response is successful and redirectOnSuccess is passed in
        if (response?.success && redirectOnSuccess) {

            // They both are

            // Navigate to the redirect location
            navigate(redirectOnSuccess);

        }

    };



    return {
        formValues,
        handleChange,
        handleSubmit,
        fieldErrors,
        formError,
        loading,
        apiResponse,
    };
};

export default useFormHandler;