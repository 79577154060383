// Function to set a cookie
export function SetCookie(cookie_name, cookie_value, days) {

    // Make a new date
    const d = new Date();

    // Set the date to the current date + however many days specified
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));

    // Set the expires string with the new date
    let expires = "expires=" + d.toUTCString();

    // Add the cookie
    //document.cookie = cookie_name + "=" + cookie_value + ";" + expires + ";path=/;SameSite=None; Secure";
    document.cookie = cookie_name + "=" + cookie_value + ";" + expires + ";path=/;SameSite=Strict";

};


// Function to get a cookie
export function GetCookie(name) {

    // Get a cookie using regex to find the name and return the value
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);

    if (match) {

        return match[2]

    };

};