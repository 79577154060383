// Import react elements
import React from "react";

// Import shared grid styles
import "./Grid.css";



export const Grid = ({ children, className = "", gap = "20px" }) => {

    return (

        <div
            className={`grid-container ${className}`}
            style={{ "--gap": gap }}
        >

            {children}

        </div>

    );

};



export const GridItem = ({ children, className = "" }) => {

    return <div className={`grid-item ${className}`}>{children}</div>;

};


