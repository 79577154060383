// Import react elements
import { useEffect } from "react";


// A custom hook to set the page title dynamically
const usePageTitle = (title) => {

    useEffect(() => {

        // Check if there is a title
        if (title) {

            // There is

            // Set browser tab title
            document.title = `Strichsuite - ${title}`;

        }

        // Run on title change
    }, [title]);

};

export default usePageTitle;
