// Import react elements
import { useContext } from "react";
import { Navigate } from "react-router-dom";

// Import context function
import { AuthContext } from "global/context/AuthContext";


// If there is a token then the user can access the site - else send them to the login page

// A Higher-Order Component (HOC) that restricts access to authenticated users only
const ProtectedRoute = ({ children }) => {

    // Get the authentication token from the AuthContext (determines whether the user is logged in)
    const { token } = useContext(AuthContext);

    // If there is a token return the children (allow access to protected routes) else navigate to the login page
    return token ? children : <Navigate to="/Login" />;

};

// Export the component so it can be used to wrap protected routes in the App
export default ProtectedRoute;