// Import react elements
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Import core functions
import { activateNewAccount } from "api/APICoreCalls"

// Import utility functions
import { SetCookie } from "utilities/Cookies";

// Import pages
import Login from "../LoginPage";



const ActivateAccount = () => {


    // Set location to useLocation() which gives access to the current URL/location object
    const location = useLocation();



    // Set navigate to useNavigate() which provides a function to programmatically navigate between routes
    const navigate = useNavigate();



    // Run once on page load
    useEffect(() => {

        // Set the url parameters
        const urlParams = new URLSearchParams(location.search);

        // Set action to the url 'action' type
        const action = urlParams.get("action");

        // Check if the url action equals 'activate_account'
        if (action === "activate_account") {

            // It does

            // Set token to the one in the url
            const token = urlParams.get("token");

            // Set email to the one in the url
            const email = urlParams.get("email");

            // Check if there is a token AND email
            if (token && email) {

                // There is both

                // Run the api call
                activateNewAccount(email, token)

                    // Get the api call response
                    .then((response) => {

                        //console.log(response);

                        // Check if the response is successful
                        if (response.success) {

                            // It is

                            // Set the prefill_email cookie to the url email
                            SetCookie("prefill_email", urlParams.get('email'), 1);

                            // Navigate the the login page
                            navigate("/login");

                            // NEED TO ADD DIALOG BOX HERE
                            // Success, please log in!

                        } else {

                            // It isn't

                            //console.error("Activation failed:", response.error_message);

                            // Navigate to the login page
                            navigate("/login"); 

                            // NEED TO ADD DIALOG BOX HERE
                            alert("Your account has NOT been activated: " + response.error_message)

                        }
                    })

                    // Catch for errors
                    .catch((err) => {

                        console.error("Activation error:", err);

                        // Navigate the to login page
                        navigate("/login");

                    });

            } else {

                // Navigate the to login page
                navigate("/login");

            }

        } else {

            // Navigate the to login page - default fallback
            navigate("/login");

        }

    }, [location, navigate]);



    // Return the login page (the url is still the activate one - which is why we want to nagivate to 'login' after api call)
    return <Login />;


    
};

export default ActivateAccount;