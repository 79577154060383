// Import components
import { GridItem } from "global/components/Grid";
import ConnectionsTable from "./ConnectionsTable";



const Connections = (connectionsData) => {



    return (

        <GridItem>

            <h3>Connections</h3>


            { connectionsData ?

                <ConnectionsTable connectionsData={connectionsData} />

                :

                <p>Loading...</p>

            }
                       

        </GridItem>

    );

};

export default Connections;