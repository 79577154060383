
// Import hooks
import usePageTitle from "global/hooks/usePageTitle";

// ImagesPage.js
const Images = () => {

    // Set title inside the page
    usePageTitle("Images"); 

    return <h1>Images</h1>;
};

export default Images;