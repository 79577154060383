// Import react elements
import { useContext } from "react";

// Import context function
import { UserContext } from "global/context/UserContext";

// Import hooks
import usePageTitle from "global/hooks/usePageTitle";

// Import components
import { Grid } from "global/components/Grid";
import Connections from "./components/Connections";



// AccountPage.js
const Account = () => {

    // Set title inside the page
    usePageTitle("Account - Connections");

    // Extract the connections data from the user context - allows us to use their data anywhere inside this component without needing to manually pass it down as a prop
    const { connectionsData } = useContext(UserContext);


    return (

        <>

            <Grid>

                {Connections(connectionsData)}

            </Grid>

        </>

    );

};

export default Account;