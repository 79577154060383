// Import react elements
import { createContext, useState, useEffect } from "react";

// Import core functions
import { loginUser } from "api/APICoreCalls";



// Create authentication context
export const AuthContext = createContext();



// AuthProvider is a Context Provider component that wraps the entire app
// It gives all components inside access to authentication-related data and functions (e.g. token, login, logout).
export const AuthProvider = ({ children }) => {



    // Get the stored token
    const getStoredToken = () => {

        // Either from local storage if available or null if not
        return localStorage.getItem("token") || null;

    };



    // Set the state for token - use the stored token if there is one
    const [token, setToken] = useState(getStoredToken());

    const [authReady, setAuthReady] = useState(false);



    // When the app starts, check if there's a saved login token. If there is, use it to set the user as logged in.
    useEffect(() => {

        // Get the stored token
        const storedToken = getStoredToken();

        // Check if the stored token exists
        if (storedToken) {

            // It does, so set the token to the stored one
            setToken(storedToken);

        }

        // Mark as ready whether token exists or not
        setAuthReady(true); 

    // Runs once when the component mounts (because of the empty [] dependency array)
    }, []);



    // Function to log user in 
    const login = async (email, password) => {

        // Make the API call to log in and get the response
        const login_user_response = await loginUser(email, password);

        // Check if there is a token in the response 
        if (login_user_response.token) {

            // There is, so set the token state
            setToken(login_user_response.token);

            // Set it in local storage
            localStorage.setItem("token", login_user_response.token);

        }

        // Return the api call response
        return login_user_response;

    };



    // Function to log user out
    const logout = () => {

        // Clear the token
        setToken(null);

        // Remove token from local storage
        localStorage.removeItem("token");

        // Clear all local storage
        localStorage.clear();

    };



    return (

        // The AuthContext.Provider makes authentication data and functions (like the token, login, and logout) 
        // available to all child components that use the AuthContext via useContext().
        <AuthContext.Provider value={{ token, authReady, login, logout }}>

            {/* This renders the entire app inside the provider, giving everything access to auth */}
            {children}

        </AuthContext.Provider>

    );



};