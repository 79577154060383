// Import react elements
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";



const Sidebar = ({ toggleSidebar, sidebarOpen, isMobile }) => {



    // Set layout for pages and sub pages
    const sidebarLinks = [
        {
            label: "Dashboard",
            icon: "/images/icon_profile_w.png",
            to: "/dashboard",
        },
        {
            label: "Orders",
            icon: "/images/icon_profile_w.png",
            to: "/orders",
        },
        {
            label: "Account",
            icon: "/images/icon_profile_w.png",
            to: "/account",
            subRoutes: [
                { label: "Connections", to: "/account/connections" },
            ],
        },
        {
            label: "Images",
            icon: "/images/icon_profile_w.png",
            to: "/images",
        },

        // Add more sections here

    ];



    // Set location to useLocation() which gives access to the current URL/location object
    const location = useLocation();

    // Set toggle state - tracks user toggle
    const [manuallyToggled, setManuallyToggled] = useState(false); 

    // Set sub page menu dropdown state - tracks what's open
    const [openDropdown, setOpenDropdown] = useState(null);

    // Function to toggle sub page menu list open/clsoed
    const toggleDropdown = (label) => {

        // Mark it as user-toggled
        setManuallyToggled(true); 

        // Only open it, don't close it onClick
        setOpenDropdown((prev) => (prev !== label ? label : prev));

    };
    

    // Auto-open the dropdown when a subpage is active
    useEffect(() => {

        // Check if the user manually toggled the dropdown, if so skip this auto-open logic
        if (manuallyToggled)

            // Prevents the dropdown from being immediately overridden when navigating
            return;

        // Find the sidebar group that contains a sub-route matching the current path
        const active = sidebarLinks.find(link =>

            // Checks if we're inside a subpage like /account/connections, /account/stores, etc.
            link.subRoutes?.some(sub => location.pathname.startsWith(sub.to))

        );

        // Check if an active group is found, open its dropdown
        if (active) {

            // Open its dropdown
            setOpenDropdown(active.label);

        } else {

            // If no matching group found (we navigated outside), close any open dropdown
            setOpenDropdown(null); // auto-close if no match
            
        }

        // Runs on route changes
    }, [location.pathname]);




    useEffect(() => {

        // Check if the user toggled the dropdown
        if (!manuallyToggled)

            // They didn't so return early
            return;

        // Create a timeout to toggle the state
        const timeout = setTimeout(() => {

            // Set toggle to false
            setManuallyToggled(false);

           // slight delay to prevent race condition
        }, 300); 

        // Return and clear the timeout
        return () => clearTimeout(timeout);

        // Run when user manually toggles
    }, [manuallyToggled]);



    return (

        <aside className={`sidebar ${sidebarOpen ? "open" : "closed"} ${isMobile ? "is_mobile" : "is_desktop"}`}>


            <div className="sidebar--logo">

                { /* <img src={sidebarOpen ? "/images/logo_wide.png" : "/images/logo_thin.png"} alt="Strichsuite" /> */}

                {isMobile ?

                    <img src="/images/logo_mobile.png" alt="Strichsuite" className="logo_mobile" />

                    :

                    <img src="/images/logo_wide.png" alt="Strichsuite" className={sidebarOpen ? "" : "logo"} />

                }

                {isMobile ?

                    sidebarOpen ?

                        <div>
                            <button className="header-container-left__toggle-sidebar-btn" onClick={toggleSidebar}>{sidebarOpen ? "<" : "+"}</button>
                        </div>

                        :

                        ""

                    :

                    ""

                }


            </div>


            <nav>


                <ul className="sidebar-nav">

                    {sidebarLinks.map((link) => {

                        const isActiveGroup = link.subRoutes?.some((sub) => location.pathname.startsWith(sub.to));

                        if (link.subRoutes) {

                            return (

                                <li
                                    key={link.label}
                                    className={`sidebar-dropdown ${isActiveGroup ? "active" : ""} ${openDropdown === link.label ? "open" : ""
                                        }`}
                                >
                                    <div className="sidebar-dropdown__toggle">

                                        <NavLink
                                            to={link.to}
                                            className="sidebar-dropdown__link"
                                            activeclassname="active"
                                            onClick={() => toggleDropdown(link.label)}
                                        >
                                            <img src={link.icon} alt={`${link.label} icon`} className="sidebar-nav-item__icon" />
                                            <span className="sidebar-nav-item__title">{link.label}</span>
                                            <div className="sidebar-dropdown__arrow" aria-label="Toggle account submenu">
                                                {openDropdown === link.label ? "-" : "+"}
                                            </div>
                                        </NavLink>

                                        

                                    </div>

                                    {openDropdown === link.label && (
                                        <ul className="sidebar-submenu">
                                            {link.subRoutes.map((sub) => (
                                                <li key={sub.to}>
                                                    <NavLink to={sub.to} activeclassname="active">
                                                        {sub.label}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>

                            );

                        }

                        return (

                            <li key={link.to}>

                                <NavLink to={link.to} activeclassname="active">
                                    <img src={link.icon} alt={`${link.label} icon`} className="sidebar-nav-item__icon" />
                                    <span className="sidebar-nav-item__title">{link.label}</span>
                                </NavLink>

                            </li>

                        );
                    })}

                </ul>




                { /*

                <ul>

                    <li>
                        <NavLink to="/dashboard" activeclassname="active">
                                <img src="/images/icon_profile_w.png" alt="icon_home" className="sidebar-nav-item__icon" />
                                <span className="sidebar-nav-item__title">Dashboard</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/orders" activeclassname="active">
                                <img src="/images/icon_profile_w.png" alt="icon_home" className="sidebar-nav-item__icon" />
                                <span className="sidebar-nav-item__title">Orders</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/images" activeclassname="active">
                                <img src="/images/icon_profile_w.png" alt="icon_home" className="sidebar-nav-item__icon" />
                                <span className="sidebar-nav-item__title">Images</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/account" activeclassname="active">
                                <img src="/images/icon_profile_w.png" alt="icon_home" className="sidebar-nav-item__icon" />
                            <span className="sidebar-nav-item__title">Account</span>
                        </NavLink>
                    </li>
                        <li className="sub-page">
                            <NavLink to="/connections" activeclassname="active">
                                <img src="/images/icon_profile_w.png" alt="icon_home" className="sidebar-nav-item__icon" />
                                <span className="sidebar-nav-item__title">Connections</span>
                            </NavLink>
                        </li>

                        

                </ul>

                */}

            </nav>

        </aside>
    );



};

export default Sidebar;