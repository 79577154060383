// Import hooks
import usePageTitle from "global/hooks/usePageTitle";



const Dashboard = () => {

    // Set title inside the page
    usePageTitle("Dashboard"); 

    return <h1>Welcome to the Dashboard</h1>;

};

export default Dashboard;



