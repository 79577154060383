// Import react elements
import { useContext } from "react";

// Import context function
import { UserContext } from "global/context/UserContext";

// Import hooks
import usePageTitle from "global/hooks/usePageTitle";

// Import components
import { Grid } from "global/components/Grid";
import AccountDetails from "./components/AccountDetails";
import UpdatePassword from "./components/UpdatePassword";



// AccountPage.js
const Account = () => {

    // Set title inside the page
    usePageTitle("Account");

    // Extract the `login` function from the authentication context - allows us to call `login()` anywhere inside this component without needing to manually pass it down as a prop
    const { userData } = useContext(UserContext);


    return (

        <>

            <Grid>

                {AccountDetails(userData)}

                {UpdatePassword(userData)}

            </Grid>

        </>
        
    );

};

export default Account;